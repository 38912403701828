import { createContext } from "react";
const initialstate = {
  que1: "",
  que2: "",
  que3: "",
  que4: "",
};
const EnergyContext = createContext(initialstate);
EnergyContext.displayName = "EnergyContext";

export default EnergyContext;
